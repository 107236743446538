var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row bg-white" }, [
    _c(
      "div",
      { staticClass: "col-12" },
      [
        _c(
          "c-search-box",
          { on: { enter: _vm.getList } },
          [
            _c("template", { slot: "search" }, [
              _c(
                "div",
                {
                  staticClass: "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                },
                [
                  _c("c-plant", {
                    attrs: { type: "search", name: "plantCd" },
                    on: { datachange: _vm.getList },
                    model: {
                      value: _vm.searchParam.plantCd,
                      callback: function ($$v) {
                        _vm.$set(_vm.searchParam, "plantCd", $$v)
                      },
                      expression: "searchParam.plantCd",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                },
                [
                  _c("c-vendor", {
                    attrs: {
                      editable: _vm.editable,
                      label: "업체",
                      name: "assessVendorId",
                    },
                    on: { input: _vm.getList },
                    model: {
                      value: _vm.searchParam.assessVendorId,
                      callback: function ($$v) {
                        _vm.$set(_vm.searchParam, "assessVendorId", $$v)
                      },
                      expression: "searchParam.assessVendorId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                },
                [
                  _c("c-process", {
                    attrs: {
                      editable: _vm.editable,
                      label: "공종",
                      name: "processCd",
                    },
                    on: { datachange: _vm.getList },
                    model: {
                      value: _vm.searchParam.processCd,
                      callback: function ($$v) {
                        _vm.$set(_vm.searchParam, "processCd", $$v)
                      },
                      expression: "searchParam.processCd",
                    },
                  }),
                ],
                1
              ),
            ]),
          ],
          2
        ),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-6" },
            [
              _c("apexchart", {
                ref: "riskLevelChart",
                attrs: {
                  height: "380",
                  type: "bar",
                  options: _vm.riskLevelChange.chartOptions,
                  series: _vm.riskLevelChange.series,
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-6" },
            [
              _c("apexchart", {
                ref: "imprLevelChart",
                attrs: {
                  height: "380",
                  type: "bar",
                  options: _vm.imprLevelChange.chartOptions,
                  series: _vm.imprLevelChange.series,
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-12" },
            [
              _c("c-table", {
                ref: "table",
                attrs: {
                  title: "잔존 위험요인 리스트",
                  columns: _vm.grid.columns,
                  filtering: false,
                  data: _vm.grid.data,
                  merge: _vm.grid.merge,
                  gridHeight: _vm.grid.height,
                },
                scopedSlots: _vm._u([
                  {
                    key: "suffixTitle",
                    fn: function () {
                      return [
                        _c("font", { attrs: { color: "#C10015" } }, [
                          _vm._v(
                            " ※ 개선조치가 필요하며, 개선완료가 되지 않은 위험요인 "
                          ),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "customArea",
                    fn: function ({ props, col }) {
                      return [
                        col.name === "customCol" &&
                        props.row["sopImprovementIds"] &&
                        props.row["ibmStepNames"]
                          ? _vm._l(
                              _vm.$comm.ibmTagItems2(props.row),
                              function (item, index) {
                                return _c(
                                  "q-chip",
                                  {
                                    key: index,
                                    staticStyle: {
                                      "margin-bottom": "4px !important",
                                    },
                                    attrs: {
                                      color: item.color,
                                      clickable: true,
                                      "text-color": "white",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.linkClick(item, props.row)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(" " + _vm._s(item.title) + " "),
                                    _c("c-assess-tooltip", {
                                      attrs: {
                                        ibmTooltip: item.ibmTooltip,
                                        ibmClassCd: item.ibmClassCd,
                                      },
                                    }),
                                  ],
                                  1
                                )
                              }
                            )
                          : col.name === "afterRisk"
                          ? [
                              _c(
                                "div",
                                { staticClass: "description-td" },
                                [
                                  _c(
                                    "font",
                                    {
                                      staticClass: "text-negative",
                                      staticStyle: {
                                        "font-size": "1.4em",
                                        "font-weight": "500",
                                      },
                                    },
                                    [_vm._v(_vm._s(props.row["afterRiskName"]))]
                                  ),
                                ],
                                1
                              ),
                            ]
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ]),
        _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }