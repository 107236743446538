<template>
  <div class="row bg-white">
    <div class="col-12">
      <c-search-box @enter="getList">
        <template slot="search">
          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
            <!-- 사업장 -->
            <c-plant
              type="search"
              name="plantCd"
              v-model="searchParam.plantCd"
              @datachange="getList"
            />
          </div>
            <!-- 해당년도 -->
          <!-- <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
            <c-datepicker
              :range="true" 
              type="year"
              label="해당년도"
              v-model="searchParam.assessmentYears"
              @datachange="yearchange"
            ></c-datepicker>
          </div> -->
          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
            <c-vendor
              :editable="editable"
              label="업체"
              name="assessVendorId"
              v-model="searchParam.assessVendorId"
              @input="getList"
              >
            </c-vendor>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
            <c-process
              :editable="editable"
              label="공종"
              name="processCd"
              v-model="searchParam.processCd"
              @datachange="getList"
              >
            </c-process>
          </div>
          <!-- <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
            <c-select
              type="search"
              itemText="codeName"
              itemValue="code"
              name="afterRisk"
              label="판단결과"
              codeGroupCd= "RAM_RISK_TYPE_CD"
              v-model="searchParam.afterRisk"
              @datachange="getList"
            ></c-select>
          </div> -->
        </template>
      </c-search-box>
      <div class="row">
        <div class="col-6">
          <apexchart
            ref="riskLevelChart"
            height="380"
            type="bar"
            :options="riskLevelChange.chartOptions"
            :series="riskLevelChange.series"
          >
          </apexchart>
        </div>
        <div class="col-6">
          <apexchart
            ref="imprLevelChart"
            height="380"
            type="bar"
            :options="imprLevelChange.chartOptions"
            :series="imprLevelChange.series"
          >
          </apexchart>
        </div>
        <div class="col-12">
          <c-table
            ref="table"
            title="잔존 위험요인 리스트"
            :columns="grid.columns"
            :filtering="false"
            :data="grid.data"
            :merge="grid.merge"
            :gridHeight="grid.height"
          >
            <template v-slot:suffixTitle>
              <font color="#C10015">
                ※ 개선조치가 필요하며, 개선완료가 되지 않은 위험요인
              </font>
            </template>
            <template v-slot:customArea="{ props, col }">
              <template v-if="col.name==='customCol' && props.row['sopImprovementIds'] && props.row['ibmStepNames']">
                <q-chip
                  v-for="(item, index) in $comm.ibmTagItems2(props.row)"
                  :key="index"
                  :color="item.color"
                  :clickable="true"
                  text-color="white"
                  style="margin-bottom:4px !important;"
                  @click="linkClick(item, props.row)">
                  {{item.title}}
                  <c-assess-tooltip 
                    :ibmTooltip="item.ibmTooltip"
                    :ibmClassCd="item.ibmClassCd"
                  />
                </q-chip>
              </template>
              <template v-else-if="col.name==='afterRisk'">
                <div class="description-td">
                   <font class="text-negative" style="font-size:1.4em;font-weight:500;">{{props.row['afterRiskName']}}</font>
                </div>
              </template>
            </template>
          </c-table>
        </div>
      </div>
      <c-dialog :param="popupOptions"></c-dialog>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import selectConfig from "@/js/selectConfig";
import VueApexCharts from "vue-apexcharts";
export default {
  name: "risk-assessment-all-status",
  components: {
    apexchart: VueApexCharts,
  },
  props: {},
  data() {
    return {
      searchParam: {
        plantCd: null,
        assessmentYears: [],
        afterRisk: null,
        processCd: null,
        assessVendorId: null,
      },
      grid: {
        columns: [
          {
            name: 'assessVendorName',
            field: 'assessVendorName',
            label: '업체',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'processName',
            field: 'processName',
            label: '공종',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'sopName',
            field: 'sopName',
            label: '작업',
            align: 'left',
            style: 'width:200px',
            sortable: false,
          },
          {
            name: 'riskHazardClassCd',
            field: 'riskHazardClassCd',
            label: '유해·위험요인<br/>(위험한 상황과 사건)',
            align: 'left',
            style: 'width:250px',
            sortable: false,
          },
          {
            name: 'currentSafetyMeasures',
            field: 'currentSafetyMeasures',
            label: '현재안전조치',
            align: 'left',
            style: 'width:170px',
            sortable: false,
          },
          {
            name: 'afterRisk',
            field: 'afterRisk',
            label: '판단결과',
            align: 'center',
            style: 'width:90px',
            type: 'custom',
            sortable: false,
          },
          {
            name: 'improvementMeasures',
            field: 'improvementMeasures',
            label: '추가 리스크관리 계획',
            align: 'left',
            style: 'width:170px',
            sortable: false,
          },
          {
            name: 'customCol',
            field: 'customCol',
            label: '개선',
            align: 'center',
            style: 'width:160px',
            type: 'custom',
            sortable: false
          },
        ],
        merge: [
          { index: 0, colName: 'assessVendorId' },
          { index: 1, colName: 'processName' },
        ],
        data: [],
        // height: '500px'
      },
      riskLevelChange: {
        chartOptions: {
          title: {
            text: '위험성평가 추세 변화'
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '45%',
              endingShape: 'rounded'
            },
          },
          legend: {
            position: 'top',
            horizontalAlign: 'left',
            offsetX: 40
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
          },
          xaxis: {
            categories: [],
            labels: {
              style: {
                fontSize: '12px'
              },
              formatter: function (val) {
                return  val
              }
            }
          },
          yaxis: {
            title: {
              text: '판단결과',
              style: {
                fontSize: '14px',
                fontWeight: 600,
              },
            },
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val + " 건"
              }
            }
          },
          fill: {
            opacity: 1,
          },
        },
        series: [
          {
            name: '상',
            data: [],
          },
          {
            name: '중',
            data: [],
          },
          {
            name: '하',
            data: [],
          },
        ],
      },
      imprLevelChange: {
        chartOptions: {
          title: {
            text: '부적합/개선 추세 변화'
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '45%',
              endingShape: 'rounded'
            },
          },
          legend: {
            position: 'top',
            horizontalAlign: 'left',
            offsetX: 40
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
          },
          xaxis: {
            categories: [],
            labels: {
              style: {
                fontSize: '12px'
              },
              formatter: function (val) {
                return  val
              }
            }
          },
          yaxis: {
            title: {
              text: '판단결과/부적합',
              style: {
                fontSize: '14px',
                fontWeight: 600,
              },
            },
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val + " 건"
              }
            }
          },
          fill: {
            opacity: 1,
          },
        },
        series: [
          {
            name: '진행 건 수',
            data: [],
          },
          {
            name: '완료 건 수',
            data: [],
          },
          {
            name: '지연 건 수',
            data: [],
          },
        ],
      },
      popupOptions: {
        isFull: false,
        target: null,
        title: "",
        visible: false,
        width: "80%",
        param: {},
        closeCallback: null,
      },
      editable: true,
      fullscreen: false,
      riskChangeUrl: "",
      residualRiskUrl: '',
    };
  },
  computed: {
  },
  watch: {},
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  methods: {
    init() {
      //// role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.riskChangeUrl = selectConfig.saas.status.riskLevel.url;
      this.imprChangeUrl = selectConfig.saas.status.imprLevel.url;
      this.residualRiskUrl = selectConfig.saas.status.residual.url 
      // code setting
      
      // this.searchParam.assessmentYears = [String(Number(this.$comm.getThisYear()) - 3), this.$comm.getThisYear()]
      // this.yearchange();
      this.getList();
      // list setting
    },
    // yearchange() {
    //   if (!this.searchParam.assessmentYears || this.searchParam.assessmentYears.length === 0) {
    //     window.getApp.$emit("ALERT", {
    //       title: "안내", // 안내
    //       message: "해당년도를 선택하세요.", // 해당년도를 선택하세요.
    //       type: "warning", // success / info / warning / error
    //     });
    //     return;
    //   }
    //   let startYear = Number(this.searchParam.assessmentYears[0]);
    //   let lastYear = Number(this.searchParam.assessmentYears[1]);

    //   this.riskLevelChange.chartOptions.xaxis.categories = [];
    //   this.imprLevelChange.chartOptions.labels = [];

    //   for (let year = startYear; year <= lastYear; year++) {
    //     if (year === startYear) {
    //       this.riskLevelChange.chartOptions.xaxis.categories.push(year);
    //       this.imprLevelChange.chartOptions.labels.push(year);
    //     } else if (year === lastYear) {
    //       this.riskLevelChange.chartOptions.xaxis.categories.push(year);
    //       this.imprLevelChange.chartOptions.labels.push(year);
    //     } else {
    //       this.riskLevelChange.chartOptions.xaxis.categories.push(year);
    //       this.imprLevelChange.chartOptions.labels.push(year);
    //     }
    //   }
    //   this.getList();
    // },
    getList() {
      let name = this.searchParam.afterRisk === 'RRT0000001' ? '하' : this.searchParam.afterRisk === 'RRT0000005' ? '중' : '상'
      this.$set(this.imprLevelChange.series[0], 'name', '판단결과 ' + name + ' 건수')
      this.riskChange();
      this.imprChange();
      this.residualRiskList();
    },
    riskChange() {
      this.$http.url = this.riskChangeUrl;
      this.$http.type = "GET";
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        // series.data length 구하기
        this.$set(this.riskLevelChange.chartOptions.xaxis, 'categories', this.$_.map(_result.data, 'plantName'))
        let series = [
          {
            name: '상',
            data: this.$_.map(_result.data, 'highCount')
          },
          {
            name: '중',
            data: this.$_.map(_result.data, 'mediumCount')
          },
          {
            name: '하',
            data: this.$_.map(_result.data, 'lawCount')
          },
        ];
        this.$set(this.riskLevelChange, 'series', series)

        this.riskLevelChange.chartWidth = '100%';
        this.$refs["riskLevelChart"].refresh();
      });
    },
    imprChange() {
      this.$http.url = this.imprChangeUrl;
      this.$http.type = "GET";
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {

        this.$set(this.imprLevelChange.chartOptions.xaxis, 'categories', this.$_.map(_result.data, 'plantName'))
        let series = [
          {
            name: '진행 건 수',
            data: this.$_.map(_result.data, 'cntIng'),
          },
          {
            name: '완료 건 수',
            data: this.$_.map(_result.data, 'cntComplete'),
          },
          {
            name: '지연 건 수',
            data: this.$_.map(_result.data, 'cntInaction'),
          }
        ];

        this.$set(this.imprLevelChange, 'series', series)
        console.log(this.imprLevelChange)

        this.imprLevelChange.chartWidth = '100%';
        this.$refs["imprLevelChart"].refresh();
      })
    },
    residualRiskList() {
      // 선택된 위험성평가 정보가 있는 경우
      this.$http.url = this.residualRiskUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam
      this.$http.request((_result) => {
        this.grid.data = this.$_.reject(_result.data, {afterRisk: null})
      })
    },
    linkClick(data, row) {
      this.requestImprRow = row
      this.imprData = data;
      if (data.ibmClassCd === 'IC00000001') {
        this.popupOptions.title = '개선';
        this.popupOptions.param = {
          sopImprovementId: data.sopImprovementId,
        };
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
        this.popupOptions.width = '80%';
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closeImprPopup;
      } else {
        this.popupOptions.title = '즉시조치';
        this.popupOptions.param = {
          sopImprovementId: data.sopImprovementId,
        };
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprImmDetail.vue'}`);
        this.popupOptions.width = '80%';
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closeImprPopup;
      }
    },
    closeImprPopup(result, flag) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (result && result.col1) {
        if (flag !== 'delete') {
          let splitId = this.$_.split(this.requestImprRow.sopImprovementIds, ',');
          let splitStep = this.$_.split(this.requestImprRow.ibmStepNames, ',');
          if (splitId && splitId.length > 0) {
            let idIndex = this.$_.indexOf(splitId, result.col1.sopImprovementId);
            splitStep[idIndex] = result.col1.ibmStepName;
            this.requestImprRow.ibmStepNames = splitStep.toString();
          }
        } else {
          let splitId = this.$_.split(this.requestImprRow.sopImprovementIds, ',');
          let splitStep = this.$_.split(this.requestImprRow.ibmStepNames, ',');
          let splitClass = this.$_.split(this.requestImprRow.ibmClassCds, ',');
          if (splitId && splitId.length > 0) {
            let idIndex = this.$_.indexOf(splitId, result.col1.sopImprovementId);
            splitId.splice(idIndex, 1)
            splitStep.splice(idIndex, 1)
            splitClass.splice(idIndex, 1)
            this.$set(this.requestImprRow, 'sopImprovementIds', splitId.toString())
            this.$set(this.requestImprRow, 'ibmStepNames', splitStep.toString())
            this.$set(this.requestImprRow, 'ibmClassCds', splitClass.toString())
          }
        }
        this.$refs['table'].$refs['compo-table'].resetVirtualScroll();
        this.residualRiskList();
      }
    },
  },
};
</script>